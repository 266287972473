import { Avatar, Box, Button, Fab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Apis/config";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import AddIcon from "@mui/icons-material/Add";
import DeleteDailog from "../../../Component/Dailog/DeleteDailog";
import AddCover from "../../../Component/Dailog/AddCover";
import { GET_COVER_LIST } from "../../../Apis/cover_api";
import SplitButton from "../../../Component/Button/SplitButton";

function Cover() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectdRow, setSelectedRows] = React.useState(null);

  const getData = async (v = 1) => {
    setIsLoading(true);

    const data = await GET_COVER_LIST(v ? rows.length : 0);

    if (v) {
      setRows([...rows, ...(data.r ?? [])]);
    } else {
      setRows([...(data.r ?? [])]);
    }
    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenD = () => {
    setOpenD(true);
  };

  const handleOnClose = async (v) => {
    setOpen(false);
    setSelectedRows(null);
    if (v) {
      getData(0);
    }
  };

  const handleOnCloseD = async (v) => {
    setOpenD(false);
    // if (v) {
    //     const d = await API_BANNER_DELETE({ id: selectedId });
    //     setSelectedId(0);
    //     if (d && d.s) {
    //         ToastShow("Deleted Successfully");
    //         getData(0);
    //     } else {
    //         ToastShow("Opps! something went wrong please try again.");
    //     }
    // }
  };

  useEffect(() => {
    getData(0);
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };

  const handelDelete = (id) => {
    setSelectedId(id);
    handleClickOpenD();
  };

  const onEdit = (id) => {
    const t = rows.filter((v) => v.id == id);
    setSelectedRows(t[0]);
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      headerAlign: "center",
      align: "center",
      width: 50,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "cover",
      headerName: "Cover",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return (
          <Box
            onClick={() => window.open(`${API_URL.coverImage}${params.value}`)}
            sx={{
              height: "60px",
              width: "60px",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "fill",
              }}
              src={`${API_URL.coverImage}${params.value}`}
            />
          </Box>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={null}
          title={params.value}
          onEdit={onEdit}
        />
      ),
    },
  ];

  return (
    <>
      <DataGrideView
        columns={columns}
        rows={rows}
        // callBack={callBack}
        isLoading={isLoading}
      />
      <Fab
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
        }}
        size="medium"
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>

      {open ? (
        <AddCover open={open} handleOnClose={handleOnClose} row={selectdRow} />
      ) : null}
      {openD ? (
        <DeleteDailog
          open={openD}
          handleOnClose={handleOnCloseD}
          title="Delete Category!"
          subtitle="Are you sure you want to delete category?"
        />
      ) : null}
    </>
  );
}

export default Cover;
