import * as React from "react";
import { Settings, Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

export default function SplitButton({
  dataId,
  isDelete = 1,
  isEdit = 1,
  isView,
  onView,
  onEdit,
  onDelete,
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Avatar
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        sx={{
          height: "35px",
          width: "35px",
          cursor: "pointer",
        }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        color="inherit"
      >
        <Settings />
      </Avatar>

      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 2 }}>
                  {isView && (
                    <ListItemButtonView
                      icon={<Visibility sx={{ height: "20px" }} />}
                      text={"View"}
                      onClick={() => {
                        onView(dataId);
                        handleClose();
                      }}
                    />
                  )}
                  {isEdit && (
                    <ListItemButtonView
                      icon={<Edit sx={{ height: "20px" }} />}
                      text={"Edit"}
                      onClick={() => {
                        handleClose();
                        console.log(dataId)
                        onEdit(dataId);
                       
                      }}
                    />
                  )}
                  {isDelete && (
                    <ListItemButtonView
                      icon={<Delete sx={{ height: "20px" }} />}
                      text={"Delete"}
                      onClick={() => {
                        onDelete(dataId);
                        handleClose();
                      }}
                    />
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

function ListItemButtonView({ icon, text, onClick }) {
  return (
    <ListItemButton
      sx={{ borderRadius: "10px", p: "0px", py: "3px", px: "1px" }}
      onClick={onClick}
    >
      <ListItemIcon sx={{ p: "0px" }}>{icon}</ListItemIcon>
      <ListItemText
        sx={{ p: "0px" }}
        primary={<Typography variant="body2">{text}</Typography>}
      />
    </ListItemButton>
  );
}
