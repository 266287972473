import { createTheme } from "@mui/material/styles";

export const theme = (mode) => {
  return createTheme({
    typography: {
      ...(mode === "light"
        ? {
            allVariants: {
              color: "#000",
            },
            subtitle2: {
              color: "#575353",
            },
            subtitle1: {
              color: "#746e6e",
            },
            fontFamily: ["DM Sans", "sans-serif"].join(","),
          }
        : {
            allVariants: {
              color: "#fff",
            },
            subtitle2: {
              color: "#e6dada",
            },
            subtitle1: {
              color: "#e6dada",
            },
            fontFamily: ["DM Sans", "sans-serif"].join(","),
          }),
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            common: {
              black: "#dbd5d5",
            },
            primary: {
              main: "#FF6042",
            },
            secondary: {
              main: "#f2d0c9",
              dark: "#497858",
            },
            background: {
              default: "#F5F8FA",
              paper: "#fff",
            },

            divider: "#ebe1e1",
          }
        : {
            primary: {
              main: "#FF6042",
            },
            secondary: {
              main: "#151521",
              dark: "#c5aa7d8f",
            },
            background: {
              default: "#151521",
              paper: "#1E1E2D",
            },
            divider: "#5a5a6e65",
            common: {
              black: "#0000000",
            },
          }),
    },
  });
};
