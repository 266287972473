import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_USERS_LIST = async (role,c) => {
    const res = await GETAPI(API_URL.getUserList,"&role="+role+"&count="+c);
    return res;
  };

export const CHANGE_STATUS_USER = async (id,status) => {
    const formData = new FormData();
    formData.append("id",id);
    formData.append("status",status);
    const res = await POSTAPI(API_URL.updateUser,formData);
    return res;
};
export const DELETE_USER = async (fId,id) => {
    const formData = new FormData();
    formData.append("fId",fId);
    formData.append("id",id);
    const res = await POSTAPI(API_URL.deleteUser,formData);
    return res;
};
  