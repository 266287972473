import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { theme } from "./Utils/theme";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/system";
import { BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home/Home";
import AuthContext from "./Context/AuthContext";
import AuthService from "./Service/AuthService";
import LoginAuth from "./Pages/Auth/LoginAuth";
import ToastView from "./Component/Toast/Toast";
import Users from "./Pages/Screens/Users/Users";
import Creator from "./Pages/Screens/Users/Creator";
import Cover from "./Pages/Screens/Cover/Cover";
import Dashboard from "./Pages/Screens/Dashboard/Dashboard";
import SkinType from "./Pages/Screens/SkinType/SkinType";
import SkinConcern from "./Pages/Screens/SkinConcern/SkinConcern";
import Version from "./Pages/Screens/Version/Version";

function App() {
  // THEME MODE
  const [mode, setMode] = useState("light");

  // CHNAGE THEME MODE
  const changeMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  // MATERIAL UI SETUP
  return (
    <>
      <AuthContext>
        <ThemeProvider theme={theme(mode)}>
          <BrowserRouter>
            <Box
              sx={{
                overflow: "hidden",
                bgcolor: "background.default",
                height: "100vh",
                width: "100vw",
                overflowX: "hidden",
              }}
            >
              {/* ROUTES */}
              <Routes>
                <Route path="/" element={<LoginAuth />} />
                <Route
                  path="dashboard"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Dashboard"
                        children={<Dashboard />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="users"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Users"
                        children={<Users />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="creator"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Creator"
                        children={<Creator />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="cover-collection"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Cover Collection"
                        children={<Cover />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="skin-type"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Skin Type"
                        children={<SkinType />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="skin-concern"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Skin Concern"
                        children={<SkinConcern />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="version"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Version"
                        children={<Version />}
                      />
                    </AuthService>
                  }
                />
              </Routes>
            </Box>
          </BrowserRouter>
        </ThemeProvider>
      </AuthContext>
      <ToastView />
    </>
  );
}

export default App;
