import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material";

function DataGrideView({
  rows,
  columns,
  isVisible = 1,
  width = "100%",
  isDisplayFooter = "block",
  callBack = () => {},
  isLoading = false,
}) {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(10);

  return (
    // <SplitButton title={"a"} />
    <motion.div
      animate={{
        // height: "100%",
        width: width,
        opacity: isVisible,
      }}
    >
      {/* //declare type GridNativeColTypes = 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect'; */}
      {/* declare type GridAlignment = 'left' | 'right' | 'center'; */}
      <DataGrid
      
        loading={isLoading}
        // getRowId={(row) => row.statId}
        // autoHeight
        rows={rows}
        columns={columns}
        onPageChange={(np) => {
          callBack((np + 1) * pageSize);
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        rowsPerPageOptions={[10, 15, 30]}
        componentsProps={{
          pagination: { classes: null },
        }}
        disableSelectionOnClick
        getRowHeight={() => "auto"}
        // checkboxSelection
        sx={{
          height: "70vh",
          overflow: "visible",
          boxShadow: 2,
          border: 2,
          borderColor: "divider",

          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid ${
              theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
          },
          '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${
              theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
            }`,
          },

          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "background.paper",
          },
          "& .MuiDataGrid-footerContainer": {
            display: isDisplayFooter,
          },
          "& .MuiDataGrid-cell": {
            borderColor: "divider",
            overflow: "visible",
            py: 2,
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
          "& .MuiDataGrid-columnHeader": {
            borderColor: "divider",
          },
          "& .MuiDataGrid-columnsContainer": {
            borderColor: "divider",
          },
        }}
      />
    </motion.div>
  );
}

export default DataGrideView;
