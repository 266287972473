import axios from "axios";

export const API_URL = {
  baseUrl: 
  // "https://glowy.supersyntax.dev/",

  // "https://api.glowysocial.com/",
  "https://api-new.glowysocial.com/",

  sign_in: "api/auth/sign-in",

  getUserList: "api/admin/user-list",

  updateUser: "api/user/update",
  deleteUser: "api/user/delete-by-admin",

  createCover: "api/cover-collection/create",
  updateCover: "api/cover-collection/update",
  listCover: "api/cover-collection/get-list",

  analitics: "api/admin/analitics",

  getSkinType: "api/auth/get-skin-type",
  addUpdateSkinType: "api/auth/add-update-skin-type",
  deleteSkinType: "api/auth/delete-skin-type",
  deleteSkinConcern: "api/auth/delete-skin-concern",
  addUpdateSkinConcern: "api/auth/add-update-skin-concern",
  getSkinConcern: "api/auth/get-skin-concern",

  getVersion: "api/version/get-version",
  addUpdateVersion: "api/version/add-update",

  coverImage: "https://api-new.glowysocial.com/upload/cover_collection/",
  profileImage: "https://api-new.glowysocial.com/upload/profile/",
};

export const API = axios.create({ baseURL: API_URL.baseUrl });

export async function GETAPI(url, data = "") {
  try {
    const auths = `?apikey=${tokens.apikey}&token=${tokens.token}`;
    const result = await API.get(url + auths + data, {
      headers: null,
    });
    // console.log(result.data);
    return result.data;
  } catch (e) {
    //console.log(e);
    return null;
  }
}

export async function POSTAPI(url, data) {
  try {
    data.append("apikey", tokens.apikey);
    data.append("token", tokens.token);
    const result = await axios.post(API_URL.baseUrl + url, data, {
      headers: null,
    });
    //console.log(result.data);
    return result.data;
  } catch (e) {
    //console.log(e);
    return null;
  }
}

const tokens = {};
export default tokens;
