import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { API_ANALITICS } from "../../../Apis/analitics_api";
import chartData from "../../../Service/ChartData";
import Chart from "react-apexcharts";


function Dashboard() {

  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({});


  async function getData() {
    setIsLoading(true);
    const r = await API_ANALITICS();

    if (r && r.r) {
      setData(r.r);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    isLoading ? <Box
    display="flex"
    justifyContent="center"
    >
      <CircularProgress />

    </Box> : <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: 5,
        height: "90vh",
        overflowY: "scroll",
      }}
    >
      <Box sx={{ p: 5, flex: 1 }}>
        <Chart
          className="apex-charts"
          options={chartData().options}
          series={chartData(data.list).series}
          type="bar"
          height={480}
        />
      </Box>
      {/* <DataView data={data} /> */}
    </Box>
  );
}

export default Dashboard;

function DataView({data}) {
  return (
    <Paper
      elevation={0}
      sx={{
        m: 1,
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        mx: 5,
      }}
    >
      <Typography variant="h5" sx={{ mb: 1 }}>
        Analitics
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <CardView title="Total Creator" unites={data.t_creator_user} />
        <CardView title="Total User" unites={data.t_user} />
        <CardView title="Total Routine" unites={data.t_routine} />
        <CardView title="Total Private Routine" unites={data.t_private_routine} />
      </Box>
    </Paper>
  );
}

function CardView({ title, unites }) {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        border: "dotted",
        borderRadius: "10px",
        borderColor: "divider",
        borderWidth: "2px",
        p: 1,
        py: 1.3,
        m: 1,
        display: "flex",
        flexDirection: "Column",
      }}
    >
      <Typography variant="subtitle1" sx={{ color: "primary.main" }}>
        {title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            mr: 0.4,
            backgroundColor: "primary.main",
          }}
        />
        <Typography variant="body2">{unites}</Typography>
      </Box>
    </Box>
  );
}
