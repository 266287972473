import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import Compressor from "compressorjs";
import { PhotoCamera } from "@mui/icons-material";
import TextFieldView from "../TextField/TextFieldView";import { ADD_UPDATE_SKIN_TYPE } from "../../Apis/skin_api";

function AddSkinType({ open, handleOnClose, row }) {
  const [title, setTitle] = React.useState(row ? row.title : "");
  const [description, setDescription] = React.useState(
    row ? row.description : ""
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async () => {
    if (!title) {
      ToastShow("Please enter title");
    } else if (!description) {
      ToastShow("Please enter description");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_SKIN_TYPE({
        title: title,
        description: description,
        id: row ? row.id : null,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog
      fullWidth
      open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{row ? "Update" : "Add"} Skin Type</DialogTitle>
        <DialogContent
          sx={{
           
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextFieldView
            label="Title"
            value={title}
            required
            sx={{
              mt: 1,
              width:"100%"
            }}
            onchange={(v) => setTitle(v)}
          />
          <TextFieldViewM
            label="Description"
            value={description}
            required
            sx={{
              mt: 1,
            }}
            onchange={(v) => setDescription(v)}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddSkinType;

function TextFieldViewM({
  onchange,
  label,
  value,
  type = "text",
  variant = "outlined",
  sx = {},
  required = false,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...sx,
      }}
    >
      <TextField
      fullWidth
        multiline
        rows={4}
        required={required}
        size="small"
        sx={{ ...sx }}
        label={label}
        placeholder={label}
        type={type}
        value={value}
        onChange={(e) => {
          onchange(e.target.value);
        }}
        variant={variant}
      />
    </Box>
  );
}
