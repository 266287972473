import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_COVER = async (data) => {
  const formData = new FormData();

  if(data.cover){
    formData.append("cover", data.cover,"cover.png");
  }

  if (data.row) {
    formData.append("id", JSON.stringify(data.row.id));
  }

  const res = await POSTAPI(
    data.row ? API_URL.updateCover : API_URL.createCover,
    formData
  );

  return res;
};

export const GET_COVER_LIST = async (c) => {
  const res = await GETAPI(API_URL.listCover,"&count="+c);

  return res;
};
